.icon-arrow-left {
  width: 33px;
  height: 53px;
}

.icon-arrow-right {
  width: 33px;
  height: 53px;
}

.icon-facebook {
  width: 34px;
  height: 34px;
}

.icon-instagram {
  width: 34px;
  height: 34px;
}

.icon-menu {
  width: 57px;
  height: 15px;
}

.icon-rhomb {
  width: 25px;
  height: 25px;
}

.icon-rhomb-border {
  width: 28px;
  height: 28px;
}

.icon-rhomb-brown {
  width: 25px;
  height: 25px;
}

.icon-search {
  width: 23px;
  height: 23px;
}

.icon-youtube {
  width: 48px;
  height: 34px;
}
